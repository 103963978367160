<template>
  <div>
    <save
      ref="saveForm"
      :tabid="tab_id"
      :regions="regions.data"
      @onSuccess="getItems"
    />
    <b-tabs>
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab.id)"
      >
        <b-overlay :show="loading">
          <good-table-column-search
            :items="items.data"
            :columns="tab_id == 2 ? column_cities : columns"
            :total="items.total"
            :page="page"
            :filter="filterModel"
            @getItems="getItems"
            @add="$refs.saveForm.visible = true"
            @edit="(item) => $refs.saveForm.edit(item)"
            @delete="destroy"
            @onPageChange="(p) => page = p"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '../../table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      tab_id: 1,
      filterModel: {
        per_page: 10,
        number: null,
        address: null,
        calculating_type_id: null,
      },
      settingTabs: [
        {
          id: 1,
          name: this.$t('Областы'),
        },
        {
          id: 2,
          name: this.$t('Регионы'),
        },
      ],
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Наименование(uz)'),
          field: 'name_uz',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Наименование(ru)'),
          field: 'name_ru',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ],
      column_cities: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Район'),
          field: 'region.name_uz',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('Все'),
            filterDropdownItems: [],
          },
        },
        {
          label: this.$t('Наименование(uz)'),
          field: 'name_uz',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Наименование(ru)'),
          field: 'name_ru',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      regions: 'region/GET_ITEMS',
      cities: 'city/GET_ITEMS',
    }),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
    this.getRegionsAction({ per_page: 999 })
      .then(res => {
        this.column_cities.map(item => {
          if (item.field === 'region.name_uz') {
            item.filterOptions.filterDropdownItems = res.data.data.map(i => {
              i.value = i.id
              i.text = i.name_uz
              return i
            })
          }
        })
      })
  },
  methods: {
    ...mapActions({
      getRegionsAction: 'region/index',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
    }),
    async getItems() {
      this.loading = true
      if (this.tab_id == 1) {
        await this.getRegionsAction({ ...this.filterModel, page: this.page })
        this.items = this.regions
      } else if (this.tab_id == 2) {
        await this.getCitiesAction({ ...this.filterModel, page: this.page, relations: 'region' })
        this.items = this.cities
      }
      this.loading = false
    },
    onChange(id) {
      this.tab_id = id
      this.getItems()
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
