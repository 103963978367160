var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[(_vm.tabid == 2)?_c('b-form-group',{attrs:{"label":_vm.$t('Выберите область'),"label-for":"region_id"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Выберите область'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"region_id","state":errors.length > 0 ? false:null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите область'),"options":_vm.regions},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('Именование(uz)'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Именование(ru)'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name_ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name_ru),callback:function ($$v) {_vm.$set(_vm.form, "name_ru", $$v)},expression:"form.name_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }